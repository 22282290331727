import { BiEventLogger } from './biEventLogger';
import { EventLogger } from './types';

declare global {
  interface Window {
    APP_ENDPOINT: string | null | undefined;
    clientEventLogger: EventLogger;
    IS_EMBEDDED_SCHEDULE: boolean;
    isIframeView: boolean;
  }
}

window.clientEventLogger = BiEventLogger.getInstance();

export { BiEventLogger };
export * from './types';
