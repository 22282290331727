import isEmpty from 'lodash/isEmpty';
import { getCsrfToken, getXsrfToken } from '@smartsheet/api.utils';

window.isIframeView = window.location.pathname.includes('/2.0/rm');
const BASE_API_URL = `${window.APP_ENDPOINT || ''}/api/v1`;
const SERVICE_BASE_URL = `${window.APP_ENDPOINT || ''}/api`;
const ADMIN_BASE_URL = `${window.APP_ENDPOINT || ''}/api/admin/v1`;

const getFields = ({ fields }) =>
  !isEmpty(fields) ? `?fields=${fields.join(',')}` : '';

// Advanced Report URLs
export const getAdvancedReportURL = (advancedReportId, forceRefresh = false) =>
  `${BASE_API_URL}/advanced_reports/${advancedReportId}${
    forceRefresh ? `?force_refresh=${forceRefresh}` : ''
  }`;
export const refreshAdvancedReportDataURL = () =>
  `${BASE_API_URL}/advanced_reports/refresh_data`;

export const accountSettingsURL = `${SERVICE_BASE_URL}/account_settings`;
export const getProjectURL = (projectId, fields = {}) =>
  `${BASE_API_URL}/projects/${projectId}${getFields(fields)}`;
export const getTheseProjectsURL = (projectIds) =>
  `${BASE_API_URL}/assignables?with_archived=true&fields=entity_mapping&id=${projectIds}&per_page=9999`;
export const getProjectsQueryURL = (queryString) =>
  queryString
    ? `${BASE_API_URL}/projects/query${queryString}`
    : `${BASE_API_URL}/projects/query`;
export const getUsersQueryURL = (queryString) =>
  queryString
    ? `${BASE_API_URL}/users/query${queryString}`
    : `${BASE_API_URL}/users/query`;

export const ssoSettingsURL = `${BASE_API_URL}/sso_settings`;
export const putSsoURL = (settingsId) => `${ssoSettingsURL}/${settingsId}`;
export const getLinkStatusForSmartsheetAccount = `${BASE_API_URL}/smartsheet/organization`;
export const postDisableSsoURL = `${ssoSettingsURL}/disable_sso`;

export const getAuditRequestURL = (type) => `${BASE_API_URL}/audit/${type}`;
export const getAuditDownloadURL = (type, queryId) =>
  `${getAuditRequestURL(type)}/file/${queryId}`;

export const getCreatePhaseURL = (projectId) =>
  `${BASE_API_URL}/projects/${projectId}/phases`;
export const getPhasesURL = (projectId) =>
  `${BASE_API_URL}/projects/${projectId}/phases?per_page=9999`;
export const getPhaseURL = (phaseId) => `${BASE_API_URL}/projects/${phaseId}`;

export const getAssignmentsURL = (projectId) =>
  `${BASE_API_URL}/projects/${projectId}/assignments?per_page=9999&with_phases=true&fields=subtask_counts,row_id`;
export const getAssignmentsByAssignableURL = ({
  projectId,
  assignableId,
  perPage,
  page,
}) =>
  assignableId
    ? `${BASE_API_URL}/projects/${projectId}/assignments/grouped_by_assignable?assignable_id=${assignableId}&per_page=${perPage}&page=${page}&fields=subtask_counts,row_id`
    : `${BASE_API_URL}/projects/${projectId}/assignments/grouped_by_assignable?per_page=${perPage}&page=${page}&fields=subtask_counts,row_id`;
export const getAssignmentURL = (assignmentId, fields = {}) =>
  `${BASE_API_URL}/assignments/${assignmentId}${getFields(fields)}`;
export const assignmentCopyURL = `${BASE_API_URL}/assignments?fields=subtask_counts`;
export const getAssignmentTasksURL = ({ assignableId, assignmentId }) =>
  `${BASE_API_URL}/projects/${assignableId}/assignments/${assignmentId}/subtasks`;
export const getAllAssignmentTasksURL = ({ assignableId, assignmentId }) =>
  `${getAssignmentTasksURL({ assignableId, assignmentId })}?per_page=9999`;
export const getTaskURL = (assignableId, assignmentId, taskId) =>
  `${getAssignmentTasksURL({ assignableId, assignmentId })}/${taskId}`;
export const getProjectAssignmentsURL = (assignableId) =>
  `${BASE_API_URL}/projects/${assignableId}/assignments?fields=subtask_counts`;
export const getProjectResourceRequestsURL = (assignableId) =>
  `${BASE_API_URL}/projects/${assignableId}/resource_requests?per_page=1000`;
export const getProjectUsersURL = (assignableId, withPhases = true) =>
  `${BASE_API_URL}/projects/${assignableId}/users?with_archived=true&include_placeholders=true&with_phases=${withPhases}&allow_blank=true&per_page=9999`;
export const getUserAssignmentsURL = (userId, from, to) =>
  `${BASE_API_URL}/users/${userId}/assignments?from=${from}&to=${to}&per_page=9999&fields=subtask_counts,row_id`;
export const getUserApproversURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/approvers`;
export const getUserApproveesURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/approvees`;

export const getUsersURL = ({
  perPage = 9999,
  withArchived = true,
  includePlaceholders = true,
}) =>
  `${BASE_API_URL}/users?with_archived=${withArchived}&include_placeholders=${includePlaceholders}&per_page=${perPage}&allow_blank=true`;
export const getUsersMinimalURL = (page, extraParams = '') =>
  `${BASE_API_URL}/users?minimal_response=true&sort_field=first_name&allow_blank=false&per_page=50&page=${page}${extraParams}`;
export const getUsersSearchURL = (searchPath, searchString, extraParams) =>
  `${BASE_API_URL}/${searchPath}?query=${searchString}${extraParams}&minimal_response=true`;
export const getScheduleSearchURL = (searchPath, searchString, extraParams) =>
  `${BASE_API_URL}/${searchPath}?query=${searchString}${extraParams}&minimal_response=true&per_page=9`;

export const getUserURL = (userId) => `${SERVICE_BASE_URL}/users/${userId}`;
export const getUserV1URL = (userId) => `${BASE_API_URL}/users/${userId}`;
export const getUserCustomFieldsURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/custom_field_values?per_page=9999&info_page_visible=true`;
export const getUserStatusURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/statuses`;
export const getUsersAvailabilitiesURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/availabilities`;
export const getStatusOptionsURL = () =>
  `${BASE_API_URL}/status_options?with_deleted=true&per_page=99`;

export const getCustomField = (id) => `${BASE_API_URL}/custom_fields/${id}`;

export const getOrgProjectStatesURL = () =>
  `${BASE_API_URL}/tags?namespace=project state`;
export const getUserTagsURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/tags?namespace=personal%20project%20view&per_page=9999`;
export const getOrganizationTagsURL = () =>
  `${BASE_API_URL}/tags?namespace=organization%20project%20view&per_page=9999`;
export const getUserSaveViewURL = (userId) =>
  userId
    ? `${BASE_API_URL}/tags/save_view?user_id=${userId}`
    : `${BASE_API_URL}/tags/save_view`;

export const getUserPeopleTagsURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/tags?namespace=personal%20people%20view&per_page=9999`;
export const getOrganizationPeopleTagsURL = `${BASE_API_URL}/tags?namespace=organization%20people%20view&per_page=9999`;

export const getUserApprovalsTagsURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/tags?namespace=personal%20approvals%20view&per_page=9999`;

export const getUserSchedulePeopleSaveViewURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/tags?namespace=personal%20schedule%20people%20view&per_page=9999`;
export const getOrganizationSchedulePeopleSaveViewURL = `${BASE_API_URL}/tags?namespace=organization%20schedule%20people%20view&per_page=9999`;
export const getUserScheduleProjectsSaveViewURL = (userId) =>
  `${BASE_API_URL}/users/${userId}/tags?namespace=personal%20schedule%20projects%20view&per_page=9999`;
export const getOrganizationScheduleProjectsSaveViewURL = `${BASE_API_URL}/tags?namespace=organization%20schedule%20projects%20view&per_page=9999`;

export const getReportsRowsEndpoint = `${BASE_API_URL}/reports/rows`;
export const getReportsTotalsEndpoint = () => `${BASE_API_URL}/reports/totals`;
export const getCustomFieldsURL = () =>
  `${BASE_API_URL}/custom_fields?per_page=9999&report_visible=true&minimal_response=true`;
export const getProjectCustomFieldsURL = (projectId) =>
  `${BASE_API_URL}/projects/${projectId}/custom_field_values?per_page=9999&info_page_visible=true`;
export const getProjectsCustomFieldsURL = () =>
  `${BASE_API_URL}/custom_fields?per_page=9999&namespace=assignables&report_visible=true&minimal_response=true`;
export const getPeopleCustomFieldsURL = () =>
  `${BASE_API_URL}/custom_fields?per_page=9999&namespace=users&report_visible=true&minimal_response=true`;

export const getTagURL = (tagId) => `${BASE_API_URL}/tags/${tagId}`;
export const tagURL = `${BASE_API_URL}/tags`;
export const saveTagURL = `${BASE_API_URL}/tags/save_view`;

// These four endpoints currently only used in account switcher
export const getAssociatedUsersAndOrgs = (userId, perPage = 20, page = 1) =>
  `${BASE_API_URL}/sessions/get_associated_users_and_orgs?userId=${userId}&per_page=${perPage}&page=${page}`;
export const updateSession = (userId) => `${BASE_API_URL}/sessions/${userId}`;
export const ssoRedirect = (guid) => `/saml/sso/?guid=${guid}`;
export const smarSessionSignIn = (multiUserId, orgId) =>
  `${SERVICE_BASE_URL}/sessions/signin?multiuserid=${multiUserId}&organization_id=${orgId}`;
export const getMultiUserErrorPage = () =>
  `${BASE_API_URL}/federated_login/multi_user_error`;

export const basicUserURL = (userId) => `${BASE_API_URL}/users/${userId}`;
export const basicGetUsersWithDeletedURL = () =>
  `${SERVICE_BASE_URL}/users?with_deleted=true`;
export const unarchive = (userId) =>
  `${SERVICE_BASE_URL}/users/${userId}/undelete`;
export const resendInviteURL = (userId) =>
  `${SERVICE_BASE_URL}/users/${userId}/resendinvite`;
export const passwordResetURL = () => `${BASE_API_URL}/logins/passwordreset`;

export const getOrgModulesURL = (orgId) =>
  `${ADMIN_BASE_URL}/organizations/${orgId}/modules`;
export const getGloballyEnabledModulesURL = (known = false) =>
  `${ADMIN_BASE_URL}/modules?known=${known}`;
export const getAddModuleEnabledByDefaultURL = () =>
  `${ADMIN_BASE_URL}/modules`;
export const getEnableModuleForExistingOrgsURL = () =>
  `${ADMIN_BASE_URL}/global_modules`;

export const tagByNamespaceURL = (namespace) =>
  `${BASE_API_URL}/tags?namespace=${namespace}`;

export const csrfToken = getCsrfToken();

export const buildPayload = ({ method, payload }) => {
  const fullPayload = {
    credentials: 'include',
    method,
    headers: {
      // eslint-disable-next-line quote-props
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify(payload),
  };
  if (window.isIframeView) {
    fullPayload.headers['x-smar-xsrf'] = getXsrfToken();
  }
  return fullPayload;
};

export const buildFileUploadPayload = (formData) => {
  const fullPayload = {
    credentials: 'include',
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    body: formData,
  };
  if (window.isIframeView) {
    fullPayload.headers['x-smar-xsrf'] = getXsrfToken();
  }
  return fullPayload;
};
