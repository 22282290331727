import {
  Event,
  EventLogger,
  ProductArea,
  EventArea,
  FeatureArea,
  UsageSource,
} from './types';
import { getCsrfToken } from '@smartsheet/api.utils';
import merge from 'lodash/merge';

export class BiEventLogger implements EventLogger {
  private static INSTANCES: { [endpoint: string]: EventLogger } = {};
  private readonly SEND_INTERVAL_MS: number = 5000; // push events every 5s
  private endpoint: string;
  private events: [Event?];
  private scheduleId!: number;
  private additionalFetchOptions?: RequestInit; // We can probably remove oAuthToken after RADE-40 as it's only necessary for panel oAuth
  private featureAreasOmittingUsageSource: Set<FeatureArea>;

  static getInstance(
    endpoint?: string,
    additionalFetchOptions?: RequestInit
  ): EventLogger {
    const url = endpoint || `${window.APP_ENDPOINT}/user-events`;
    if (!BiEventLogger.INSTANCES[url]) {
      BiEventLogger.INSTANCES[url] = new BiEventLogger(
        url,
        additionalFetchOptions
      );
    }
    return BiEventLogger.INSTANCES[url];
  }

  private constructor(endpoint: string, additionalFetchOptions?: RequestInit) {
    this.endpoint = endpoint;
    this.additionalFetchOptions = additionalFetchOptions;
    this.featureAreasOmittingUsageSource =
      this.getFeatureAreasOmittingUsageSource();
    this.events = [];
    this.setup();
  }

  private setup(): void {
    this.scheduleId = window.setInterval(() => {
      void this.send().catch((error) => {
        console.error('Error sending events:', error);
      });
    }, this.SEND_INTERVAL_MS);

    addEventListener(
      'beforeunload',
      () => {
        void this.send().catch((error) => {
          console.error('Error sending events:', error);
        });
        window.clearInterval(this.scheduleId);
      },
      { once: true }
    );
  }

  private getAdditionalFetchOptions(): RequestInit {
    if (this.additionalFetchOptions) {
      return this.additionalFetchOptions;
    }
    return {};
  }

  async send(): Promise<Response | void> {
    if (this.events.length) {
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      };
      if (window.clientState?.sessionKey) {
        headers['x-smar-xsrf'] = window.clientState?.sessionKey;
      }
      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify({
          events: this.events.splice(0, this.events.length),
        }),
        headers: headers,
      };

      const mergedFetchOptions = merge(
        fetchOptions,
        this.getAdditionalFetchOptions()
      );

      return fetch(this.endpoint, mergedFetchOptions);
    }
    return Promise.resolve();
  }

  /**
   * Validates Event and raises an error if required properties are missing.
   * @param eventData - The event data to validate.
   * @throws {@link Error} If any required property is missing.
   */
  private validateEvent(event: Event) {
    const requiredEventProperties = [
      'eventOwnerType',
      'eventData',
      'eventTimestamp',
    ];
    const requiredEventDataProperties = [
      'featureArea',
      'eventName',
      'elementLocation',
      'elementName',
      'eventArea',
    ];
    requiredEventProperties.forEach((property) => {
      if (!Object.prototype.hasOwnProperty.call(event, property)) {
        throw new Error(`Missing required property: ${property}`);
      }
    });
    requiredEventDataProperties.forEach((property) => {
      if (!Object.prototype.hasOwnProperty.call(event.eventData, property)) {
        throw new Error(`Missing required property: ${property}`);
      }
    });
  }

  push(event: Event): void {
    try {
      event = this.prePushHook(event);
      this.events.push(event);
    } catch (error) {
      // console.error('Error pushing event:', error, event); MUTE to reduce spam
    }
  }

  private isSmarPlatform(): boolean {
    return (window.IS_EMBEDDED_SCHEDULE ||
      window.isIframeView ||
      window.clientState?.sessionKey) as boolean;
  }

  private getFeatureAreasOmittingUsageSource(): Set<FeatureArea> {
    return new Set([FeatureArea.RESOURCE_MANAGEMENT]);
  }

  /**
   *
   * @param featureArea - FeatureArea
   * @returns the usage source unless the feature area does not require it
   */
  private getUsageSource(featureArea: FeatureArea): UsageSource | undefined {
    if (this.featureAreasOmittingUsageSource.has(featureArea)) return undefined;
    return this.isSmarPlatform()
      ? UsageSource.VIA_SMAR
      : UsageSource.LEGACY_WEB;
  }

  /**
   * @param event - the payload triggered from the view layer
   * @returns the event with properties that globally available
   */
  private buildWithGlobalValues(event: Event): Event {
    const properties =
      event.eventData.properties &&
      typeof event.eventData.properties === 'object'
        ? event.eventData.properties
        : {};
    const featureArea = event.eventData.featureArea;
    const updatedEvent = {
      ...event,
      eventData: {
        ...event.eventData,
        properties: {
          ...properties,
          usageSource: this.getUsageSource(featureArea),
        },
        productArea: ProductArea.RESOURCE_MANAGEMENT_V2,
        eventArea: EventArea.APP_FRONTEND,
      },
      eventOwnerId: Number(window.whoami?.id) ?? undefined, // The panel will not have access to this value
      eventOwnerType: 'user',
    };

    return updatedEvent;
  }

  private prePushHook(event: Event): Event {
    event = this.buildWithGlobalValues(event);
    this.validateEvent(event);
    return event;
  }
}
