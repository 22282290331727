import React from 'react';
import PropTypes from 'prop-types';

export const LockIcon = ({ iconStyle, tooltipText }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    className={iconStyle}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.25 5.25C7.25 3.73122 8.48122 2.5 10 2.5C11.5188 2.5 12.75 3.73122 12.75 5.25V6H7.25V5.25ZM5.75 6V5.25C5.75 2.90279 7.65279 1 10 1C12.3472 1 14.25 2.90279 14.25 5.25V6H16C16.5523 6 17 6.44772 17 7V17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17V7C3 6.44771 3.44772 6 4 6H5.75ZM4.5 16.5V7.5H15.5V16.5H4.5ZM11.5 11C11.5 11.5552 11.1984 12.04 10.75 12.2993V14C10.75 14.4142 10.4142 14.75 10 14.75C9.58579 14.75 9.25 14.4142 9.25 14V12.2993C8.80165 12.04 8.5 11.5552 8.5 11C8.5 10.1716 9.17157 9.5 10 9.5C10.8284 9.5 11.5 10.1716 11.5 11Z'
      fill='currentColor' // inherits 'color' from parent
    />
    <title>{tooltipText}</title>
  </svg>
);

LockIcon.propTypes = {
  iconStyle: PropTypes.string,
  tooltipText: PropTypes.string,
};
export default LockIcon;
