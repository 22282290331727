{
  "err_activating_sso_auth": "Erro ao ativar autenticação de SSO",
  "err_company_name_length": "O nome da empresa deve ter menos de 255 caracteres.",
  "err_duplicate_value": "Este valor já existe",
  "err_email_length": "O e-mail deve ter menos de 255 caracteres.",
  "err_failed_retrieve_initial_state_from_local_storage": "Falha ao recuperar o status inicial do localStorage",
  "err_fetching_filters": "Houve um problema ao buscar essas opções de filtro.",
  "err_first_name_contains_url": "O nome não deve conter um URL.",
  "err_first_name_length": "O nome deve ter menos de 255 caracteres.",
  "err_global_modules_invalid_input": "Entrada inválida.  Verifique se você definiu um nome de módulo e selecionou pelo menos uma ação.",
  "err_invalid_email": "Insira um endereço de e-mail válido",
  "err_last_name_contains_url": "O sobrenome não deve conter um URL.",
  "err_last_name_length": "O sobrenome deve ter menos de 255 caracteres.",
  "err_no_status_for_status_panel": "Sua equipe não definiu nenhuma opção de status. Se desejar começar a usar o status, um administrador pode adicionar opções de status nas configurações da conta.",
  "err_password_length_40_chars": "Deve ter entre 8 e 40 caracteres.",
  "err_required": "Obrigatório",
  "err_unable_to_load_filter_list": "Não foi possível carregar a lista de filtros",
  "err_unknown_error": "Erro desconhecido",
  "err_updating_smartsheet_auth": "Erro ao atualizar a autenticação do Smartsheet",
  "err_whoops_something_did_not_work_as_expected": "Opa! Algo não saiu como o esperado.",
  "lbl_about_fifteen_seconds": "cerca de 15 segundos",
  "lbl_about_thirty_seconds": "cerca de 30 segundos",
  "lbl_accept": "Aceitar",
  "lbl_account_switcher": "alternador de conta",
  "lbl_activate": "Ativar",
  "lbl_activated_auth_status": "Ativada (apenas conta criada com e-mail)",
  "lbl_active": "Ativo",
  "lbl_activity": "Atividade",
  "lbl_actual_hours": "Horas reais",
  "lbl_add": "Adicionar",
  "lbl_add_group_label_filter": "Adicionar filtro de {{groupLabel}}",
  "lbl_assign_to_search_user": "Atribuir a \"{{searchInput}}\"",
  "lbl_add_lc_members": "Adicionar membros",
  "lbl_add_members": "Adicionar membros",
  "lbl_add_person": "Adicionar pessoa",
  "lbl_add_tasklist": "Adicionar lista de tarefas",
  "lbl_add_team_member": "Adicionar membro da equipe",
  "lbl_add_update_people": "Adicionar/atualizar pessoa",
  "lbl_add_work_item": "Adicionar item de trabalho",
  "lbl_added": "Adicionado",
  "lbl_all": "Tudo",
  "lbl_all_project_types": "Todos os tipos de projeto",
  "lbl_allocation_heatmap": "Mapa de calor de alocação",
  "lbl_allocation_percent": "Percentual de alocação",
  "lbl_amounts": "Quantidades",
  "lbl_amounts_and_days": "Quantidades e dias",
  "lbl_amounts_and_days_filter_display_name": "Prazo e tarifas: valores e dias",
  "lbl_amounts_and_hours": "Quantidades e horas",
  "lbl_apply_changes": "Aplicar alterações",
  "lbl_approval_access_id": "Aprovação de ID de acesso",
  "lbl_approval_status": "Status de aprovação",
  "lbl_approvals": "Aprovações",
  "lbl_approvals_card_header_project_phase_client": "Projeto: fase - cliente",
  "lbl_approve_and_lock": "Aprovar e bloquear",
  "lbl_approved": "Aprovado",
  "lbl_approved_and_locked": "Aprovado e bloqueado",
  "lbl_approved_by": "Aprovado por",
  "lbl_approver": "Aprovador",
  "lbl_approver_view": "Visualização do aprovador",
  "lbl_approvers": "Aprovadores",
  "lbl_april": "Abril",
  "lbl_archive": "Arquivar",
  "lbl_archive_projects": "Arquivar projetos",
  "lbl_archived": "Arquivado",
  "lbl_archived_label": "rótulo arquivado",
  "lbl_archived_on_date": "Arquivado em {{archiveDate}}",
  "lbl_archiving": "Arquivamento",
  "lbl_assign": "Atribuir",
  "lbl_assign_group_label": "Atribuir {{groupLabel}}",
  "lbl_assign_team_member": "Atribuir membro da equipe",
  "lbl_assign_to": "Atribuído a",
  "lbl_assignment": "Atribuição",
  "lbl_assignment_dates_colon": "Datas de atribuição:",
  "lbl_assignment_details": "Detalhes da atribuição",
  "lbl_assignment_name": "Nome da atribuição",
  "lbl_assignment_status": "Status da atribuição",
  "lbl_audit_log": "Registro de auditoria",
  "lbl_august": "Agosto",
  "lbl_authentication": "Autenticação",
  "lbl_author": "Autor",
  "lbl_automatic": "automático",
  "lbl_automatic_capitalize": "Automático",
  "lbl_availability": "Disponibilidade",
  "lbl_availability_sort_options": "Opções de classificação de disponibilidade",
  "lbl_available_hours": "Horas disponíveis",
  "lbl_back": "Voltar",
  "lbl_back_to_account_settings": "Voltar para configurações da conta",
  "lbl_back_to_list_of_requests": "Voltar para lista de solicitações",
  "lbl_based_on": "Com base em",
  "lbl_bill_rate": "Taxa de cobrança",
  "lbl_billable": "Passível de cobrança",
  "lbl_bracket_lc_none": "[nenhum]",
  "lbl_bracket_leave": "[sair]",
  "lbl_bracket_no_approver_assigned": "[Nenhum aprovador atribuído]",
  "lbl_bracket_non_phase_specific": "[Não específico a uma fase]",
  "lbl_bracket_none": "[Nenhum]",
  "lbl_brief": "Resumo",
  "lbl_budget": "Orçamento",
  "lbl_budget_amounts_filter_display_name": "Orçamentos: valores",
  "lbl_budget_days_filter_display_name": "Orçamentos: dias",
  "lbl_budget_hours_filter_display_name": "Orçamentos: horas",
  "lbl_budget_report": "Relatório do orçamento",
  "lbl_budgets": "Orçamentos",
  "lbl_calculating": "calculando",
  "lbl_cancel": "Cancelar",
  "lbl_cancelled": "cancelado",
  "lbl_capacity": "Capacidade",
  "lbl_category": "Categoria",
  "lbl_cell_phone": "Celular",
  "lbl_certificate_details": "Detalhes do certificado",
  "lbl_change_to_unmanaged_confirm": "Este usuário não poderá mais monitorar e aprovar tempo e despesas e não será mais exibido na exibição de capacidade e nos relatórios.",
  "lbl_change_to_managed": "Alterar para gerenciado",
  "lbl_change_to_unmanaged": "Alterar para não gerenciado",
  "lbl_choose_file": "Escolher arquivo",
  "lbl_client": "Cliente",
  "lbl_client_name": "Nome do cliente",
  "lbl_close": "Fechar",
  "lbl_company_name": "Nome da empresa",
  "lbl_complete": "Concluído",
  "lbl_confirm": "Confirmar",
  "lbl_confirmed": "Confirmado",
  "lbl_contact_info": "Informações de contato",
  "lbl_contact_support": "Contatar suporte",
  "lbl_contractor": "Contratante",
  "lbl_copy": "Copiar",
  "lbl_count_of_total": "{{count}} de {{total}}",
  "lbl_create": "Crie",
  "lbl_create_new_request": "* Criar nova solicitação",
  "lbl_current": "Atual",
  "lbl_current_work": "Trabalho atual",
  "lbl_custom_date_range": "Intervalo de datas personalizado",
  "lbl_custom_field": "Campo personalizado",
  "lbl_date": "Data",
  "lbl_date_string_at_time_string": "{{date}} às {{time}}",
  "lbl_date_string": "Sequência de data",
  "lbl_dates": "Datas",
  "lbl_dates_for_submitted_time": "Datas para hora de envio",
  "lbl_day_friday": "Sexta-feira",
  "lbl_day_monday": "Segunda-feira",
  "lbl_day_saturday": "Sábado",
  "lbl_day_sunday": "Domingo",
  "lbl_day_thursday": "Quinta-feira",
  "lbl_day_tuesday": "Terça-feira",
  "lbl_day_wednesday": "Quarta-feira",
  "lbl_days": "Dias",
  "lbl_deactivated": "Desativada",
  "lbl_december": "Dezembro",
  "lbl_delete": "Excluir",
  "lbl_delete_adv_report_modal": "Excluir relatório?",
  "lbl_delete_saved_report_modal": "Excluir relatório salvo?",
  "lbl_description": "Descrição",
  "lbl_difference": "Diferença",
  "lbl_difference_from_past_scheduled": "Diferença de agendados anteriormente",
  "lbl_disabled": "desabilitado",
  "lbl_discipline": "Área",
  "lbl_display_colon": "Exibir:",
  "lbl_done": "Concluído",
  "lbl_duration": "Duração",
  "lbl_duration_working_days": "Duração (dias úteis)",
  "lbl_edit_profile": "Editar perfil",
  "lbl_edit_project_information": "Editar informações do projeto",
  "lbl_edit_projects": "Editar projetos",
  "lbl_email": "E-mail",
  "lbl_email_must_be_less_than_255_characters.": "O e-mail deve ter menos de 255 caracteres.",
  "lbl_employee_number": "Número do funcionário",
  "lbl_enabled": "habilitado",
  "lbl_end": "Término",
  "lbl_end_date": "Data de término",
  "lbl_end_lc_date": "Data de término",
  "lbl_ends_at": "Termina em",
  "lbl_enter_a_name": "Inserir nome",
  "lbl_enter_rejection_reason": "Inserir motivo da recusa",
  "lbl_entry_type": "Tipo de entrada",
  "lbl_everyone": "Todas as pessoas",
  "lbl_everything": "Tudo",
  "lbl_expense": "Despesa",
  "lbl_expenses": "Despesas",
  "lbl_export": "Exportar",
  "lbl_export_people_list": "Exportar lista de pessoas",
  "lbl_export_report_to_csv": "Exportar este relatório para CSV",
  "lbl_export_time_entries_for_quickBooks": "Exportar entradas de horas para QuickBooks",
  "lbl_export_underlying_data_as_csv": "Exportar dados subjacentes como CSV",
  "lbl_failed": "Erro",
  "lbl_february": "Fevereiro",
  "lbl_fees": "Tarifas",
  "lbl_filter": "Filtro",
  "lbl_filtered": "Filtrado",
  "lbl_filters": "Filtros",
  "lbl_first_day_of_work": "Primeiro dia de trabalho",
  "lbl_first_group_by": "Primeiro grupo por",
  "lbl_first_name": "Nome",
  "lbl_first_name_lc": "Nome",
  "lbl_friday_abbr_uc": "S",
  "lbl_future": "Futuro",
  "lbl_future_scheduled": "Agendado futuramente",
  "lbl_global_modules": "Módulos globais",
  "lbl_grid_view": "Exibição em grade",
  "lbl_hours": "Horas",
  "lbl_hours_filter_display_name": "Prazo e tarifas: horas",
  "lbl_hours_per_day": "Horas por dia",
  "lbl_idp_metadata_url": "URL de metadados do IdP",
  "lbl_iframe_view_content_require": "A visualização deste conteúdo requer uma licença do Resource Management by Smartsheet.",
  "lbl_in_the_office": "No escritório",
  "lbl_incurred": "Trabalhados",
  "lbl_internal": "Interno",
  "lbl_invited": "Convidado",
  "lbl_january": "Janeiro",
  "lbl_july": "Julho",
  "lbl_june": "Junho",
  "lbl_just_now": "Agora mesmo",
  "lbl_last_and_next_ninety_days": "Últimos e próximos 90 dias",
  "lbl_last_day_of_work": "Último dia de trabalho",
  "lbl_last_month": "Mês passado",
  "lbl_last_name": "Sobrenome",
  "lbl_last_name_lc": "Sobrenome",
  "lbl_last_quarter": "Trimestre passado",
  "lbl_last_thirty_days": "Últimos 30 dias",
  "lbl_last_week": "Semana passada",
  "lbl_learn_lc_more": "Saiba mais",
  "lbl_learn_more": "Saiba mais",
  "lbl_leave": "Licença",
  "lbl_leave_type": "Tipo de licença",
  "lbl_licensed": "Licenciado",
  "lbl_licensed_users": "Usuários licenciados",
  "lbl_linked_smartsheet_project": "Projeto vinculado do Smartsheet",
  "lbl_loading": "Carregando",
  "lbl_loading_ellipsis": "Carregando...",
  "lbl_loading_project_ellipsis": "Carregando projeto...",
  "lbl_location": "Localização",
  "lbl_log_requested": "Registro solicitado",
  "lbl_make_active": "Tornar ativo",
  "lbl_make_licensed_user": "Criar usuário licenciado",
  "lbl_manage_members": "Gerenciar membros",
  "lbl_managed_resource": "Recursos gerenciados",
  "lbl_manual": "manual",
  "lbl_manual_capitalize": "Manual",
  "lbl_march": "Março",
  "lbl_matching_people": "Pessoas correspondentes",
  "lbl_matching_person": "pessoa correspondente",
  "lbl_matching_placeholders": "Espaços reservados correspondentes",
  "lbl_matching_project": "projeto correspondente",
  "lbl_matching_projects": "projetos correspondentes",
  "lbl_may": "Mai",
  "lbl_medium": "Médio",
  "lbl_members": "Membros",
  "lbl_mobile": "Móvel",
  "lbl_module": "Módulo:",
  "lbl_module_name": "Nome do módulo",
  "lbl_monday_abbr_uc": "S",
  "lbl_month": "Mês",
  "lbl_more_assignments": "mais atribuições",
  "lbl_must_be_between_8–40_characters.": "Deve ter entre 8 e 40 caracteres.",
  "lbl_my_expenses": "Minhas despesas",
  "lbl_my_projects": "Meus projetos",
  "lbl_my_time": "Minhas horas",
  "lbl_my_time_and_expenses": "Minhas horas e despesas",
  "lbl_my_views": "Minhas exibições",
  "lbl_new_project": "Novo projeto",
  "lbl_next_ninety_days": "Próximos 90 dias",
  "lbl_next_sixty_days": "Próximos 60 dias",
  "lbl_next_thirty_days": "Próximos 30 dias",
  "lbl_next_week": "Próxima semana",
  "lbl_no_assignments": "Sem atribuições",
  "lbl_no_description_provided": "Nenhuma descrição inserida.",
  "lbl_no_imports_found": "Nenhuma importação encontrada",
  "lbl_non_billable": "Não passível de cobrança",
  "lbl_none": "Nenhum",
  "lbl_non_matching": "Sem correspondência",
  "lbl_not_using_smar": "Sem uso da autenticação do Smartsheet",
  "lbl_not_using_sso": "Sem uso de SSO",
  "lbl_not_yet_invited": "Não convidado ainda",
  "lbl_notes": "Notas",
  "lbl_november": "Novembro",
  "lbl_october": "Outubro",
  "lbl_office": "Escritório",
  "lbl_office_phone": "Telefone do escritório",
  "lbl_office_status": "Status do escritório",
  "lbl_on_the_road": "Em andamento",
  "lbl_or_select_a_specific_phase": "Ou selecione uma fase específica",
  "lbl_or_select_phases": "Ou selecione fase(s)",
  "lbl_or_select_specific_phases": "Ou selecione fase(s) específica(s)",
  "lbl_other_projects": "Outros projetos",
  "lbl_out_of_office": "Fora do escritório",
  "lbl_out_sick": "Licença médica",
  "lbl_past": "Passado",
  "lbl_pending": "Pendente",
  "lbl_pending_approval": "Aprovação pendente",
  "lbl_people": "Pessoas",
  "lbl_people_filters": "Filtros de pessoas",
  "lbl_people_scheduler": "Agendador de pessoas",
  "lbl_people_tags": "Etiquetas de pessoas",
  "lbl_percentage": "Porcentagem",
  "lbl_persistent_navigation": "Navegação persistente",
  "lbl_person": "Pessoa",
  "lbl_phase": "Fase",
  "lbl_phase_dates": "Datas da fase",
  "lbl_phase_dates_colon": "Datas da fase:",
  "lbl_phase_four": "Fase quatro",
  "lbl_phase_lc_name": "Nome da fase",
  "lbl_phase_name": "Nome da fase",
  "lbl_phase_name_long_enough_to_wrap": "Nome da fase grande o suficiente para quebrar",
  "lbl_phase_one": "Fase um",
  "lbl_phase_status": "Status da fase",
  "lbl_phase_three": "Fase três",
  "lbl_phase_two": "Fase dois",
  "lbl_phases_alert_add": "As fases só podem ser adicionadas a partir da sua <0>planilha conectada</0>.",
  "lbl_phases_dates_edit": "As datas da fase só podem ser editadas alterando-se as datas das atribuições ou a partir de sua <0>planilha conectada</0>.",
  "lbl_phases_names_edit": "Os nomes da fase só podem ser editados a partir da sua <0>planilha conectada</0>.",
  "lbl_phases_delete": "As datas da fase só podem ser editadas removendo-se todas as datas das atribuições ou a partir de sua <0>planilha conectada</0>.",
  "lbl_placeholder": "Recurso temporário",
  "lbl_placeholders": "Recursos temporários",
  "lbl_please_sign_in": "Faça login para continuar",
  "lbl_plus_allocation": "+ Alocação",
  "lbl_portfolio_editor": "Editor de portfólio",
  "lbl_portfolio_reporter": "Relator de portfólio",
  "lbl_portfolio_viewer": "Visualizador de portfólio",
  "lbl_post": "Postagem",
  "lbl_project": "Projeto",
  "lbl_project_access": "Acesso ao projeto",
  "lbl_project_code": "Código do projeto",
  "lbl_project_dates": "Datas do projeto",
  "lbl_project_dates_colon": "Datas do projeto:",
  "lbl_project_description": "Descrição do projeto",
  "lbl_project_duration": "Duração do projeto",
  "lbl_project_editor": "Editor do projeto",
  "lbl_project_filters": "Filtros do projeto",
  "lbl_project_members": "Membros do projeto",
  "lbl_project_name": "Nome do projeto",
  "lbl_project_owner": "Proprietário do projeto",
  "lbl_project_owners": "Proprietários do projeto",
  "lbl_project_portfolio": "Portfólio do projeto",
  "lbl_project_properties": "Propriedades do projeto",
  "lbl_project_settings": "Configurações de projeto",
  "lbl_project_state": "Estado do projeto",
  "lbl_project_tags": "Etiquetas do projeto",
  "lbl_project_title": "Título do projeto",
  "lbl_project_type": "Tipo de projeto",
  "lbl_projects": "Projetos",
  "lbl_purchase_more": "comprar mais",
  "lbl_recents": "Recentes",
  "lbl_recent_requests": "Solicitações recentes",
  "lbl_refresh_status": "Atualizar status",
  "lbl_refresh_type": "Tipo de atualização",
  "lbl_refreshing_data": "Atualizando dados...",
  "lbl_reject": "Rejeitar",
  "lbl_rejected": "Rejeitado",
  "lbl_remaining": "Remanescente",
  "lbl_remove": "Remover",
  "lbl_report_data_refresh_status_error": "Falha na atualização de dados em {{date}}, às {{time}}",
  "lbl_report_data_refresh_status_error_please_try_again": "Ocorreu um erro ao criar o relatório avançado. Tente novamente. Caso o problema persista, contate o suporte.",
  "lbl_report_data_refresh_status_loading": "Atualizando o relatório avançado",
  "lbl_report_data_refresh_status_success": "Última atualização em {{date}}, às {{time}}",
  "lbl_report_data_refresh_status_warning_auto": "As alterações serão sincronizadas com a próxima atualização de dados",
  "lbl_report_data_refresh_status_warning_manual": "Atualize o relatório avançado para sincronizar as alterações",
  "lbl_report_name_colon": "Nome do relatório:",
  "lbl_report_title_by_criteria": "{{report}} por {{criteria}}",
  "lbl_reports": "Relatórios",
  "lbl_request_an_audit_log": "Solicitar um registro de auditoria",
  "lbl_request_audit_log": "Solicitar registro de auditoria",
  "lbl_requester": "Solicitante",
  "lbl_resend_invitation": "Reenviar convite",
  "lbl_reset": "Redefinir",
  "lbl_reset_filters": "Redefinir filtros",
  "lbl_reset_password": "Redefinir senha",
  "lbl_reset_password_for": "Redefinir senha para",
  "lbl_reset_report": "Redefinir relatório",
  "lbl_resource-request_test_page": "Página de teste de solicitações de recursos",
  "lbl_resource_management_by_smartsheet": "Resource Management by Smartsheet",
  "lbl_resourcing_admin": "Administrador de recursos",
  "lbl_retry": "Tentar novamente",
  "lbl_role": "Função",
  "lbl_saturday_abbr_uc": "S",
  "lbl_save": "Salvar",
  "lbl_save_changes": "Salvar alterações",
  "lbl_save_current_view": "Salvar visualização atual",
  "lbl_save_this_report_to_your_personal_page": "Salvar este relatório em sua página pessoal",
  "lbl_saving": "Salvando",
  "lbl_schedule": "Agenda",
  "lbl_scheduled": "Agendado",
  "lbl_search": "Pesquisar",
  "lbl_search_ellipsis": "Pesquisar...",
  "lbl_select_date_range_to_see_entries": "Selecione um intervalo de datas para ver todas as entradas do período",
  "lbl_select_people_to_add_to_project": "Selecione as pessoas que deseja adicionar a este projeto.",
  "lbl_select_phases": "Selecionar fase(s)",
  "lbl_select_project": "Selecionar projeto",
  "lbl_select_project_or_phase": "Selecionar projeto ou fase",
  "lbl_select_project_owner": "Selecionar proprietário do projeto",
  "lbl_select_team_member": "Selecionar membro da equipe:",
  "lbl_select_the_entire_project": "Selecionar o projeto inteiro",
  "lbl_send": "Enviar",
  "lbl_send_invitation": "Enviar convite",
  "lbl_september": "Setembro",
  "lbl_set_a_status": "Definir um status",
  "lbl_set_allocation": "Definir alocação:",
  "lbl_set_to_today": "Definir como hoje",
  "lbl_share": "Compartilhar",
  "lbl_shared_views": "Exibições compartilhadas",
  "lbl_show": "Mostrar",
  "lbl_show_no_phases": "Mostrar: sem fases",
  "lbl_sign_out_slo_target_url": "Sair (SLO) do URL de destino",
  "lbl_signin_sso_target_url": "Entrar (SSO) no URL de destino",
  "lbl_signup_password": "Senha (+ de 8 caracteres)",
  "lbl_skipped": "Ignorado",
  "lbl_skipped_colon": "Ignorado(s): {{number}}",
  "lbl_small": "Pequeno",
  "lbl_something_went_wrong_ellipsis": "Algo deu errado...",
  "lbl_sort_by": "Classificar por",
  "lbl_sso_for_resource_management": "SSO para Resource Management",
  "lbl_start": "Iniciar",
  "lbl_start_date": "Data de início",
  "lbl_start_lc_date": "Data de início",
  "lbl_starts_at": "Inicia em",
  "lbl_status": "Status",
  "lbl_status_cancelled": "CANCELADO",
  "lbl_status_failed": "ERRO",
  "lbl_status_message": "Mensagem de status",
  "lbl_status_queued": "NA FILA",
  "lbl_status_running": "EXECUTANDO",
  "lbl_status_succeeded": "BEM-SUCEDIDO",
  "lbl_submitted_time_for_approval": "Hora enviada para aprovação",
  "lbl_sunday_abbr_uc": "D",
  "lbl_switch_to_another_account": "Trocar para outra conta:",
  "lbl_tag": "Etiqueta",
  "lbl_tags": "Etiquetas",
  "lbl_target_utilization": "Utilização da meta",
  "lbl_tasks": "Tarefas",
  "lbl_team_member": "Membro de equipe",
  "lbl_team_member_type": "Tipo de membro da equipe",
  "lbl_tentative": "Tentativa",
  "lbl_terms": "termos",
  "lbl_the_selected_project_was": "O projeto selecionado foi ",
  "lbl_then_group_by": "E agrupar por",
  "lbl_these_results_only_include_projects_that_you_have_permission_to_see": "Esses resultados incluem apenas os projetos que você tem permissão para ver.",
  "lbl_this_month": "Este mês",
  "lbl_this_quarter": "Este trimestre",
  "lbl_this_week": "Esta semana",
  "lbl_this_year": "Este ano",
  "lbl_thursday_abbr_uc": "Q",
  "lbl_time": "Prazo",
  "lbl_time_and_expenses": "Prazo e despesas",
  "lbl_time_and_fee_categories": "Categorias de prazo e tarifa",
  "lbl_time_and_fees": "Prazos e tarifas",
  "lbl_time_and_fees_report": "Relatório de prazo e tarifas",
  "lbl_time_approval_dashboard": "Painel de aprovação de prazo",
  "lbl_time_budget_unit_of_measurement": "Unidade de medida do orçamento de prazo",
  "lbl_time_fees_amounts_and_hours_filter_display_name": "Prazo e tarifas: valores e horas",
  "lbl_time_fees_amounts_display_name": "Prazo e tarifas: valores",
  "lbl_time_fees_days_filter_display_name": "Prazo e tarifas: dias",
  "lbl_time_frame": "Período",
  "lbl_time_tracking": "Acompanhamento de prazos",
  "lbl_today": "Hoje",
  "lbl_tomorrow": "Amanhã",
  "lbl_total": "Total",
  "lbl_tuesday_abbr_uc": "T",
  "lbl_type": "Tipo",
  "lbl_unarchive": "Desarquivar",
  "lbl_unarchive_project": "Desarquivar projeto",
  "lbl_unassigned": "Não atribuído",
  "lbl_unconfirmed": "Não confirmado",
  "lbl_unmanaged": "Não gerenciado",
  "lbl_unmanaged_resource": "Recurso não gerenciado",
  "lbl_unmanaged_resources": "Recursos não gerenciados",
  "lbl_unlock": "Desbloquear",
  "lbl_upcoming": "Em breve",
  "lbl_update": "Atualizar",
  "lbl_update_status": "Atualizar status: ",
  "lbl_updated": "Atualizado",
  "lbl_updating": "Atualizando",
  "lbl_utilization": "Utilização",
  "lbl_utilization_target": "Meta de utilização",
  "lbl_vacation": "Férias",
  "lbl_values": "Valores",
  "lbl_view": "Exibir",
  "lbl_view_resourcing_schedule": "Visualizar agenda de recursos",
  "lbl_wednesday_abbr_uc": "Q",
  "lbl_week": "Semana",
  "lbl_work": "Trabalho",
  "lbl_work_email": "E-mail de trabalho",
  "lbl_work_item": "Item de trabalho",
  "lbl_work_status": "Status do trabalho",
  "lbl_working_from_home": "Trabalho de casa",
  "lbl_worklist": "Lista de trabalho",
  "lbl_your_projects_includes_the_following_properties": "Seus projetos: inclui as propriedades a seguir",
  "msg_account_owner_change_attempt": "Altere o proprietário da conta nas configurações da conta antes de arquivar este usuário.",
  "msg_activated_smartsheet_auth": "A autenticação do Smartsheet será ativada. Este método de autenticação tem efeito imediato e não pode ser desativado",
  "msg_add_assignment_to_project": "Adicionar atribuição ao projeto",
  "msg_add_notes_placeholder": "Adicionar notas, detalhes, links etc.",
  "msg_add_phase_to_project": "Adicionar fase ao projeto",
  "msg_add_project_description": "Adicionar descrição do projeto",
  "msg_add_save_current_view": "Adicionar opção de salvar visualização atual",
  "msg_add_team_member_to_project": "Adicionar membro da equipe ao projeto",
  "msg_app_project_members_disabled_tooltip": "Você é proprietário desses projetos e eles estão atribuídos a você.",
  "msg_app_project_members_enabled_tooltip": "Você é proprietário desses projetos, é um membro ou eles estão atribuídos a você.",
  "msg_audit_log_info": "Importante: um registro de auditoria fornece um resumo preciso do momento da solicitação. Ele poderá ficar desatualizado se houver mudanças nas entradas de horas e despesas após a solicitação do registro. Para obter informações mais atualizadas, solicite um novo registro acima.",
  "msg_auto_provision_checkbox": "Quando o provisionamento automático de usuário estiver ativo, ele automaticamente atribuirá licenças e o nível de permissão selecionado aos usuários. Isso também permite que os usuários evitem o processo de convite por e-mail.",
  "msg_bracket_search_to_add_filters": "[Pesquisar para adicionar filtros]",
  "msg_cannot_archive_account_owner": "Você não pode arquivar o proprietário da conta",
  "msg_cert_requirement": "Use um arquivo codificado por PEM com extensão CER, CRT ou PEM.",
  "msg_cert_upload_complete": "Carregamento de certificado concluído",
  "msg_checkbox_list_aria": "Marque ou desmarque múltiplas seleções ou clique em um botão de seleção para escolher apenas uma",
  "msg_choose_permission_type": "Escolher o tipo de permissão do usuário",
  "msg_choose_property_to_sort_by": "Escolher a propriedade para classificação.",
  "msg_close": "Fechar",
  "msg_close_and_redirect_to_profile_page": "Fechar e redirecionar para a página do perfil",
  "msg_count_matches_found": "{{count}} correspondências encontradas",
  "msg_copy_url_to_share_report": "Copie o URL abaixo para compartilhar este relatório:",
  "msg_customize_report_prompt": "Personalize seu relatório com as opções à esquerda. Quando estiver pronto, escolha uma opção abaixo.",
  "msg_delete_adv_report_modal": "Essa ação interromperá a atualização dos dados do relatório, mas não excluirá o relatório subjacente nem a planilha de origem da área de trabalho de destino.",
  "msg_delete_assignment": "Excluir atribuição",
  "msg_delete_saved_report": "Excluir relatório salvo",
  "msg_delete_saved_report_modal": "Não é possível desfazer essa ação, mas você pode criar outro relatório com as mesmas opções.",
  "msg_delete_view": "Excluir exibição",
  "msg_delete_work_item_warning": "Tem certeza de que deseja excluir este item de trabalho permanentemente? Todas as tarefas e atribuições associadas serão removidas. Esta ação não pode ser desfeita.",
  "msg_edit_allocation": "Editar alocação",
  "msg_edit_end_date": "Editar data de término",
  "msg_edit_start_date": "Editar data de início",
  "msg_edit_status": "Editar status",
  "msg_edit_team_member": "Editar membro da equipe",
  "msg_edit_work_item": "Editar item de trabalho",
  "msg_enable_bulk_editing_requirements": "Para habilitar a edição em massa, o filtro proprietário do projeto deve estar definido como você e o filtro estado do projeto deve estar definido como ativo.",
  "msg_enable_module_for_existing_organizations": "Habilitar módulo para organizações existentes",
  "msg_enable_module_for_new_organizations": "Habilitar módulo para novas organizações",
  "msg_filter_is_locked_to_the_projects_you_have_permission_to_report_on": "Este filtro está bloqueado para os projetos sobre os quais você tem permissão para relatar.",
  "msg_filter_is_locked_to_the_projects_you_own_or_are_assigned_to": "Este filtro está bloqueado para os projetos de sua propriedade ou aos quais você está atribuído.",
  "msg_grid_view_aria": "Exibição em grade: ative para escolher a exibição em grade.",
  "msg_hold_on_loading_ellipsis": "Aguarde, estamos carregando...",
  "msg_infinite_loader_aria_label": "Marque ou desmarque múltiplas seleções ou clique em um botão de seleção para escolher apenas uma",
  "msg_invalid_cert": "O certificado que você selecionou não é um tipo de arquivo válido.",
  "msg_list_view_and_project_field_selector": "Exibição em lista e selecionador do campo do projeto: ative para escolher a exibição em lista e selecionar quais campos do projeto são disponibilizados.",
  "msg_loading_approval_status": "Carregando status de aprovação...",
  "msg_loading_page": "Carregando página",
  "msg_loading_project_timeline_ellipsis": "Carregando a agenda do projeto...",
  "msg_loading_project_users_ellipsis": "Carregando usuários do projeto...",
  "msg_make_bulk_project_archive_warning": "O arquivamento desses projetos finalizará as atribuições em andamento e removerá as atribuições futuras. Esta ação não pode ser desfeita.",
  "msg_make_bulk_project_edit_confirmation": "Tem certeza de que deseja fazer essa alteração?",
  "msg_make_bulk_project_edit_warning": "Isso pode levar vários minutos, e essa ação não pode ser desfeita.",
  "msg_manage_project_membership_and_project_access_levels_based_on_their_account_permissions": "Gerenciar associação do projeto e níveis de acesso do projeto. Os níveis de acesso são exclusivos deste projeto. Algumas pessoas podem editar sem associação de acordo com as <1>permissões de conta</1>.",
  "msg_need_a_minute_ellipsis": "Opa! Aguarde um momento...",
  "msg_new_work_item_title": "Título do novo item de trabalho",
  "msg_no_assignments": "Sem atribuições",
  "msg_no_matches_found": "Não encontramos correspondências",
  "msg_no_people_assigned_to_project": "Não há pessoas atribuídas a este projeto.",
  "msg_no_phases_to_display": "Nenhuma fase para exibir",
  "msg_no_projects_match_your_criteria": "Nenhum projeto corresponde aos seus critérios",
  "msg_no_propertyies_to_display": "Sem propriedades a serem exibidas",
  "msg_no_recent_requests": "Não há solicitações recentes",
  "msg_no_results_to_display": "Nenhum resultado a ser exibido",
  "msg_no_work_for_this_project": "Não há trabalho atual para este projeto.",
  "msg_not_available": "não disponível",
  "msg_one_match_found": "Uma correspondência encontrada",
  "msg_owners_of_projects_permission_to_report_on": "Os proprietários dos projetos sobre os quais você tem permissão para relatar.",
  "msg_people_field_selector": "Selecionador do campo do projeto: ative para selecionar quais campos de dados são disponibilizados.",
  "msg_people_settings_info": "Os usuários licenciados podem monitorar prazos e tarefas em atribuições agendadas e é possível exigir que façam login. Os recursos gerenciados podem ser agendados nos projetos, mas não podem monitorar prazos e não têm acesso de login.",
  "msg_people_list_licensed_tooltip": "Com base nas permissões, os usuários licenciados podem monitorar e aprovar horários, planejar projetos, gerenciar membros da equipe, editar perfis de usuário e gerar relatórios.",
  "msg_people_list_managed_tooltip": "Atualize os Recursos gerenciados para capacidades de monitoramento de tempo.",
  "msg_people_list_unmanaged_tooltip": "Atualize os recursos não gerenciados para incluí-los na exibição de capacidade e nos relatórios.",
  "msg_people_list_archived_tooltip": "As atribuições dos usuários arquivados permanecem na página do projeto para realocação de outra pessoa ou outro recurso temporário.",
  "msg_phase_picker_copy": "Copiar para projeto ou fase",
  "msg_print_brief_page": "Imprimir página do resumo",
  "msg_profile_banner": "Banner do perfil",
  "msg_profile_information": "Informações do perfil",
  "msg_profile_navigation": "Navegação do perfil",
  "msg_profile_navigation_menu": "Menu de navegação do perfil",
  "msg_project_members_tooltip": "Você é proprietário desses projetos, é um membro ou eles estão atribuídos a você.",
  "msg_project_navigation": "Navegação do projeto",
  "msg_project_members_description": "A associação é exclusiva a este projeto. Alguns usuários podem acessar este projeto sem ser membros com base nas <0>permissões da conta</0> deles.",
  "msg_reassign_search_placeholder": "Pesquisar nome, área, etc.",
  "msg_refine_search_to_see_more_users": "Refinar a pesquisa para ver mais usuários que correspondem aos critérios",
  "msg_rejection_reason_required": "É necessário um motivo para a rejeição.",
  "msg_remove_archived_project_state_to_enable_editing": "Remova \"Arquivado\" do filtro do estado do projeto para habilitar a edição.",
  "msg_remove_assignment_confirmation": "Tem certeza de que deseja remover esta atribuição?",
  "msg_report_data_refresh_err_detail_cell_limit_exceeded": "Não foi possível atualizar os dados do relatório porque ele contém 3.000 células a mais do que o limite. Reduza o tamanho do relatório ajustando as opções no painel esquerdo. <1>Saiba mais.</1>",
  "msg_report_data_refresh_err_detail_generic": "Ocorreu um erro ao atualizar os dados do relatório avançado. Crie outro relatório avançado.",
  "msg_report_data_refresh_err_detail_row_limit_exceeded": "Não foi possível atualizar os dados do relatório porque ele contém 300 linhas a mais do que o limite. Reduza o tamanho do relatório ajustando as opções no painel esquerdo. <1>Saiba mais.</1>",
  "msg_report_data_refresh_err_detail_sheet_access": "Ocorreu um erro ao atualizar os dados do relatório avançado. Atualize as permissões da planilha ou crie outro relatório avançado.",
  "msg_report_is_too_big_to_load": "O relatório é muito grande para carregar",
  "msg_save_report_analytics_page": "Salve este relatório na página de análise",
  "msg_search_contacts": "Pesquisar contatos",
  "msg_settings_people_manage_info": "Gerencie o <1>acesso de login</1> e as <3>permissões</3> das pessoas na sua conta.<br /><5>Fale conosco por aqui</5> para fazer alterações em sua assinatura.",
  "msg_share_report": "Copie o URL pelo botão de cópia abaixo para compartilhar este relatório:",
  "msg_sheet_collaborators": "Os colaboradores da planilha em projetos vinculados não aparecem aqui. Para atualizar o compartilhamento de planilha ou adicionar colaboradores de planilhas, gerencie-os no Smartsheet. <0>Saiba mais</0>",
  "msg_signup_loading_transition": "Estamos preparando sua conta de avaliação gratuita.",
  "msg_sort_by_last_name": "Classificar por: sobrenome",
  "msg_specify_budget_in_unit": "Especificar este orçamento em {{curUnit}}",
  "msg_sso_configuration_info": "A configuração automática recomendada simplifica a configuração de SSO e permite múltiplos certificados por padrão. A configuração manual permite apenas um certificado por vez.",
  "msg_sso_enabled": "O SSO está habilitado na sua conta",
  "msg_sso_info": "O uso do login único (SSO) para gerenciamento de senha e provisionamento de usuário permite um controle maior da empresa sobre o acesso ao Resource Management, garantindo que as pessoas certas estejam fazendo login seguro no serviço.",
  "msg_sso_login_failure": "Não foi possível concluir seu login no Resource Management by Smartsheet.",
  "msg_sso_not_configured": "O SSO não foi configurado em sua conta",
  "msg_sso_unconfigurable": "O SSO não pode ser configurado diretamente no Resource Management porque sua organização está usando a autenticação do Smartsheet. As configurações do SSO podem ser gerenciadas no centro de administração do Smartsheet",
  "msg_terms": "Termos",
  "msg_thanks": "Agradecemos sua atenção!",
  "msg_there_are_no_approvals_matching_your_current_filters": "Não há aprovações correspondentes aos filtros atuais.",
  "msg_to_inactive_project": "para o projeto inativo",
  "msg_transfer_project_ownership_to_remove_user": "{{member.name}} é o proprietário do projeto e não pode ser removido. Transfira a propriedade na guia Configurações de projeto.",
  "msg_type_and_press_enter_to_add_task": "Digite e pressione Enter para adicionar uma tarefa",
  "msg_cant_edit_remove_unmanaged_resource": "{{member.name}} é um recurso não gerenciado. Atualize nas configurações da conta para editá-lo ou removê-lo deste projeto.",
  "msg_cant_add_unmanaged_resource": "{{member.name}} é um recurso não gerenciado. Atualize nas configurações da conta para adicioná-lo a este projeto.",
  "msg_upload_failed": "Falha ao carregar",
  "msg_upload_succeeded": "Carregamento bem-sucedido",
  "msg_user_default_login_tooltip": "Esta pessoa ainda não fez login usando SSO. Para trocar, peça que ela saia e retorne por meio de SSO.",
  "msg_user_smar_login_tooltip": "Esta pessoa ainda não fez login usando a autenticação do Smartsheet. Para trocar, peça que ela saia e retorne por meio da autenticação do Smartsheet.",
  "msg_wait_while_load_assignments": "Aguarde enquanto carregamos as atribuições",
  "msg_welcome_to_resource_management_by_smartsheet": "Este é o Resource Management by Smartsheet",
  "msg_work_item_description_placeholder": "Descreva o trabalho, atribuição etc.",
  "msg_you_dont_own_these_projects_and_theyre_not_assigned_to_you": "Você não é proprietário desses projetos e eles não estão atribuídos a você.",
  "msg_saved_form_already_exists": "já existe. Deseja substituir?",
  "msg_you_do_not_have_admin_access": "Verifique se você tem permissão de administrador na área de trabalho que está procurando. Você pode solicitar acesso ou criar uma área de trabalho de destino no <0>Smartsheet</0>.",
  "msg_no_matching_results": "Nenhum resultado correspondente",
  "msg_report_filter_list": "Você está na lista de filtros do relatório. Escolha os dados que gostaria de incluir no seu relatório.",
  "msg_report_filter_show_group": "Você está no grupo de exibição de filtros do relatório. Escolha as opções de cada categoria que gostaria de mostrar, selecionando todas ou nenhuma, marcando/desmarcando as caixas de seleção de múltipla escolha ou ativando um botão de agregação para escolher apenas uma opção.",
  "lbl_getting_information": "Obtendo informações",
  "lbl_actions": "Ações",
  "lbl_delete_phase": "Excluir fase",
  "lbl_chars_left": "esquerda",
  "lbl_set_to": "Configurar como",
  "msg_no_ppl_match": "Nenhuma pessoa corresponde aos seus critérios",
  "lbl_user_info_summary": "Resumo de informações do usuário",
  "lbl_saved_reports": "Relatórios salvos",
  "lbl_no_active_assignments": "Sem atribuições ativas.",
  "msg_entries_not_shown": "Entradas de projetos sem proprietário não são exibidas",
  "msg_provide_note": "Faça uma observação sobre esta aprovação. É necessária para a rejeição",
  "msg_taking_longer_than_expected": "Isto está demorando mais que o previsto. Um momento",
  "msg_want_to_make_adjustments": "Deseja fazer ajustes ou acelerar o processo? Você pode continuar usando os filtros para refinar ou reduzir o escopo do relatório.",
  "msg_calculating_time_entries": "Calculando ~{{timeEntriesCount}} entradas de tempo.",
  "lbl_just_a_few_seconds": "alguns segundos",
  "lbl_less_than_a_min": "menos de um minuto",
  "lbl_about_a_min": "cerca de um minuto",
  "msg_add_users_intro": "Use esta página para adicionar novas pessoas ou atualizar as pessoas existentes da sua conta.",
  "msg_add_projects_intro": "Use esta página para adicionar novos projetos ou atualizar os projetos existentes da sua conta.",
  "msg_more_details_can_be_found": "Mais detalhes podem ser encontrados no nosso <0>tópico de ajuda</0>. Dúvidas? <1>Contate</1> a equipe de suporte.",
  "lbl_step_1_prepare_your_data": "1. Prepare seus dados",
  "lbl_step_1_content_users": "Observação: não edite a coluna ID do usuário, pois é usada para a correspondência de atualizações de pessoas existentes.",
  "lbl_step_1_content_projects": "Observação: não edite a coluna ID do projeto, pois é usada para a correspondência de atualizações de projetos existentes.",
  "lbl_step_1_contents_create_users": "Use este modelo para adicionar novas pessoas à sua conta.",
  "lbl_step_1_contents_create_projects": "Use este modelo para adicionar novos projetos à sua conta.",
  "lbl_step_1_contents_edit_users": "Exporte uma lista de todas as pessoas da sua organização para atualizar os atributos.",
  "lbl_step_1_contents_edit_projects": "Exporte uma lista de todos os projetos da sua organização para atualizar os atributos.",
  "lbl_people_lc": "pessoas",
  "lbl_projects_lc": "projetos",
  "lbl_add_update_projects": "Adicionar/atualizar projetos",
  "lbl_download_template": "Baixar modelo",
  "lbl_update_existing": "Atualizar existente",
  "lbl_export_project_list": "Exportar lista de projetos",
  "lbl_step_2_upload_data_users": "2. Carregue sua lista de pessoas atualizada",
  "lbl_step_2_upload_data_projects": "2. Carregue sua lista de projetos atualizada",
  "msg_select_csv_file": "Selecione e carregue seu arquivo CSV atualizado para adicionar ou atualizar {{importType}}. Uma confirmação por e-mail será enviada após concluir.",
  "lbl_send_invitation_new_licensed_users": "Enviar um convite para novos usuários licenciados.",
  "lbl_upload": "Carregar",
  "lbl_processing": "Em processamento...",
  "lbl_add_new": "Adicionar novo {{importType}}",
  "msg_import_in_progress": "Importação em andamento",
  "msg_errors_during_import": "Ocorreram erros durante a importação",
  "lbl_import_complete": "Importação concluída",
  "lbl_added_colon": "Adicionado(s): {{number}}",
  "lbl_updated_colon": "Atualizado(s): {{number}}",
  "lbl_failed_colon": "Com falha: {{number}}",
  "lbl_download_error_report_csv": "Baixar CSV de relatório de erros",
  "msg_working_on_import_background": "Estamos realizando esta importação em segundo plano. Você receberá um e-mail quando a importação for concluída. Um link para esta página pode ser encontrado no rodapé da página <0>{{pageTitle}}</0>.",
  "msg_rows_with_errors": "As linhas com erros não foram adicionadas nem atualizadas. É possível baixar um relatório CSV com esses erros para corrigi-los e tentar novamente. Pode haver erros adicionais, então verifique todos os dados antes de carregar novamente. <0>Saiba mais</0> sobre importação e atualização de {{importType}}.",
  "msg_completed_processing": "O processamento de {{importType}} que você adicionou e atualizou na sua organização recentemente foi concluído.",
  "lbl_imports_last_30_days": "Importações dos últimos 30 dias",
  "lbl_characters": "caracteres ",
  "lbl_character": "caractere ",
  "lbl_remaining_lc": "remanescente",
  "msg_no_items_match_criteria": "Nenhum item corresponde aos seus critérios",
  "lbl_future_scheduled_lc": "agendado futuramente",
  "lbl_loading_details": "Carregando detalhes",
  "lbl_try_again": "Tente novamente",
  "err_must_not_be_blank": "Não deve ficar em branco",
  "err_cannot_be_more_than_255_char": "Não deve ter mais de 255 caracteres",
  "lbl_view_name": "Nome da exibição",
  "lbl_project_timelines": "Linhas do tempo de projeto",
  "msg_shorten_time_frame": "Reduza o período ou filtre para menos pessoas ou projetos e, em seguida, tente recarregar o relatório. Ou solicite um e-mail com um link para o CSV deste relatório.",
  "msg_underlying_data_requested": "Dados subjacentes solicitados. Enviaremos um e-mail a você quando estiver pronto para baixar. Pode demorar algum tempo.",
  "msg_no_report_values": "Nenhum valor para o relatório.",
  "msg_report_only_includes_data": "Esse relatório inclui apenas os dados que você tem permissão para ver.",
  "msg_we_have_logged_error": "O erro foi registrado e será analisado. Enquanto isso, tente começar novamente:",
  "msg_budgets_report_includes_all": "Este relatório inclui todos os dados de períodos trabalhados, agendados futuramente ou de orçamento para toda a duração dos projetos que têm datas sobrepostas",
  "lbl_run_report": "Executar relatório",
  "lbl_request_underlying_data": "Solicitar dados subjacentes",
  "lbl_selected": "Selecionado",
  "lbl_selected_lc": "selecionado",
  "lbl_project_lc": "projeto",
  "msg_change_client_name": "Esta ação alterará o cliente do",
  "msg_change_project_type": "Esta ação alterará o tipo do projeto do",
  "msg_change_project_owner": "Esta ação alterará o proprietário do projeto do",
  "msg_change_tags": "Esta ação alterará a(s) etiqueta(s) do",
  "msg_change_custom_fields": "Esta ação alterará os campos personalizados do",
  "msg_this_will_archive_the": "Esta ação arquivará o",
  "msg_this_will_add_selected_tags": "Esta ação adicionará a(s) etiqueta(s) selecionada(s)",
  "msg_this_will_remove_selected_tags": "Esta ação removerá a(s) etiqueta(s) selecionada(s)",
  "msg_updating_client": "Atualizando cliente para",
  "msg_updating_project_owner": "Atualizando proprietário do projeto para",
  "msg_describe_work_item_placeholder": "Descreva o trabalho, o resultado ou o produto",
  "msg_show_hide_phase_items": "Mostrar/ocultar itens de trabalho da fase {{phaseName}}",
  "msg_edit_phase": "Editar fase {{phaseName}}",
  "msg_show_hide_project_items": "Mostrar/ocultar itens de trabalho do projeto {{projectName}}",
  "msg_edit_project": "Editar projeto {{projectName}}",
  "lbl_hours_lc": "horas",
  "lbl_hours_per_day_abbr": "h/d",
  "lbl_new": "Novo",
  "lbl_add_team_member_to": "Adicionar membro da equipe a {{parentLabel}}",
  "lbl_add_work_item_to": "Adicionar item de trabalho a {{parentLabel}}",
  "lbl_available_hours_lc": "horas disponíveis",
  "lbl_remaining_or_budget_forecast": "remanescente ou {{budgetForecastPercent}}",
  "lbl_forecasted_over_or_under_budget": "previsto {{overUnder}} do orçamento ou {{budgetForecastPercent}}",
  "lbl_under_lc": "abaixo",
  "lbl_over_lc": "acima",
  "lbl_expense_budget_lc": "orçamento de despesas",
  "lbl_project_budget_lc": "orçamento do projeto",
  "lbl_expensed_lc": "gasto",
  "lbl_incurred_lc": "incorrido",
  "lbl_add_description": "Adicionar descrição",
  "lbl_automatic_configuration": "Configuração automática",
  "lbl_recommended_paren": "(Recomendado)",
  "lbl_manual_configuration": "Configuração manual",
  "lbl_manage_sheet_sharing": "Gerenciar compartilhamento de planilha",
  "lbl_cert_must_be_PEM": "O certificado deve ser codificado por PEM (isto é, arquivos .pem, .cert, .crt, .cer)",
  "msg_auto_provision_auth_users": "Usuários autenticados com provisionamento automático fora da conta (apenas criada com e-mail)",
  "lbl_selected_phases": "Usar fases selecionadas",
  "lbl_no_display_props": "Sem propriedades a serem exibidas",
  "msg_approval_audit_error": "Algo inesperado aconteceu. Se esse problema continuar, contate o suporte com detalhes em ",
  "msg_download_error": "Ocorreu um erro ao tentar baixar o arquivo: ",
  "lbl_audit_log_req": "Registro de auditoria solicitado",
  "msg_audit_log_req": "Na tabela de solicitações recentes abaixo, será exibido um link de download. Isso pode levar alguns minutos.",
  "lbl_audit_log_expense": "Registros de auditoria de despesas",
  "lbl_download": "Baixar",
  "lbl_proj_singular": "projeto estava",
  "lbl_proj_plural": "projetos estavam",
  "lbl_zero_proj": "0 projeto",
  "lbl_success_lc": "sucesso",
  "lbl_important": "<1>Importante:</1>",
  "lbl_edit_properties": "Editar propriedades",
  "lbl_error": "Erro",
  "lbl_change": "Alterar",
  "lbl_select_new": "Selecionar novo",
  "lbl_approver_project": "Aprovador: ",
  "lbl_successfully": " com sucesso.",
  "msg_update": "Atualização concluída",
  "lbl_of": "de",
  "msg_update_failed": "Falha nas atualizações.",
  "msg_update_lc": "atualizado com sucesso.",
  "lbl_edit_desc": "Editar descrição",
  "lbl_no_select": "nada selecionado",
  "lbl_current_filter_select": " : atualmente definido como ",
  "msg_getting_ready": "Preparando...",
  "msg_retry_update": "Clique em Repetir para tentar corrigir as atualizações com erro.",
  "msg_error_support": "Se o erro persistir, contate o suporte. ",
  "lbl_curr_dropdown_val": "O valor atual da lista suspensa é ",
  "msg_cancel_view": "Cancelar e retornar à lista de exibição",
  "msg_updating_tags": "Atualizando etiquetas para",
  "msg_updatings_proj_type": "Atualizando tipo do projeto para",
  "msg_start_your_trial_for_free": "Comece sua avaliação gratuita de {{trialDuration}} dias",
  "msg_create_free_account": "Criar minha conta de avaliação gratuita",
  "msg_I_agree_to_terms_and_conditions": "Concordo com os <0>Termos</0> e reconheço que o Resource Management by Smartsheet processará meus dados pessoais em conformidade com o Aviso de privacidade e a <1>Política de privacidade</1>",
  "msg_something_went_wrong_signup": "Opa! Algo deu errado com seu cadastro. Fale conosco pela <0>página de suporte</0>",
  "msg_edit_phase_settings": "Editar configurações de fase",
  "msg_edit_project_settings": "Editar configurações de projeto",
  "msg_shift_phase_dates": "Alterar datas da fase",
  "msg_shift_project_dates": "Alterar datas do projeto",
  "lbl_utilization_lc": "utilização",
  "lbl_target_utilization_lc": "utilização da meta",
  "msg_organization_using_smar_auth_modifification_info": "Sua organização está usando a autenticação do Smartsheet para fazer login no Resource Management. Para modificar essa configuração, <1>contate o suporte</1>",
  "lbl_user_auto_provisioning": "Provisionamento automático de usuário",
  "lbl_edit": "Editar",
  "lbl_permission_level": "Nível de permissão",
  "msg_activate_smar_auth_info": "Ative e permita que os usuários façam login com a conta do Smartsheet. <1>Saiba mais</1>.",
  "lbl_activate_smar_auth": "Ativar a autenticação do Smartsheet",
  "msg_please_link_rm_account_with_smar": "Para permitir a autenticação do Smartsheet, <1>vincule sua conta do Resource Management à sua conta do Smartsheet.</1>",
  "lbl_setup_sso": "Configurar SSO",
  "lbl_smartsheet_authentication": "Autenticação do Smartsheet",
  "lbl_edit_sso": "Editar configurações de SSO",
  "msg_disabled_filter_info": "Essa opção não está disponível para relatórios avançados. Crie um <0>relatório</0> para acessar essa opção.",
  "msg_people_in_org_have_logged_in_using_sso": "As pessoas da sua organização já fizeram login usando o SSO. A edição incorreta das configurações de SSO pode impedir os usuários de fazer login. Para atualizar as configurações de SSO com segurança, <1>fale conosco</1> para obter suporte.",
  "err_issue_enabling_sso": "Ocorreu um erro ao habilitar a autenticação do SSO: {{error}}. Caso esse problema persista, contate o suporte.",
  "err_activating_smar_auth": "Erro ao ativar a autenticação do Smartsheet",
  "err_issue_updating_smar_auth": "Ocorreu um erro ao atualizar a autenticação do Smartsheet: {{error}}. Caso esse problema persista, contate o suporte.",
  "err_issue_enabling_smar_auth": "Ocorreu um erro ao habilitar a autenticação do Smartsheet: {{error}}. Caso esse problema persista, contate o suporte.",
  "msg_report_unmanaged_resources_admin": "Para incluir recursos não gerenciados neste relatório, atualize-os nas <0>Configurações da conta</0>.",
  "msg_report_unmanaged_resources": "Para incluir recursos não gerenciados neste relatório, peça ao administrador que os atualize nas configurações da conta.",
  "lbl_adjust_refresh_settings": "Ajustar configurações de atualização de dados",
  "lbl_view_advanced_report": "Ver relatório avançado",
  "lbl_save_and_refresh_data": "Salvar as alterações e atualizar os dados",
  "lbl_refresh_data": "Atualizar dados",
  "lbl_delete_report": "Excluir relatório",
  "lbl_toggle_reports_control_menu": "Alternar menu de controle de relatórios",
  "lbl_advanced_report": "Relatório avançado",
  "lbl_create_new_advanced_report": "Criar relatório avançado",
  "msg_open_the_advanced_report_in_smartsheet": "Abrir o relatório avançado no Smartsheet",
  "lbl_advanced_report_edit_modal_title": "Configurações de atualização de dados",
  "lbl_advanced_report_modal_title": "Criar relatório avançado",
  "msg_advanced_report_modal_description": "Envie seus dados para uma planilha de origem e para um relatório do Smartsheet. Você pode atualizar o relatório e ajustar as configurações na página Análise.",
  "lbl_advanced_report_modal_name_field": "Nome",
  "lbl_advanced_report_modal_destination_field": "Destino",
  "lbl_advanced_report_modal_data_refresh_field": "Configuração de atualização de dados",
  "lbl_advanced_report_modal_data_refresh_auto_name": "Automático",
  "lbl_advanced_report_modal_data_refresh_auto_description": "Os dados do relatório avançado serão atualizados a cada 24 horas",
  "lbl_advanced_report_modal_data_refresh_manual_name": "Manual",
  "lbl_advanced_report_modal_data_refresh_manual_description": "Os dados devem ser atualizados manualmente na página Análise",
  "lbl_advanced_report_modal_close_button": "Fechar",
  "lbl_advanced_report_modal_create_button": "Crie",
  "msg_advanced_report_modal_need_lws": "Peça ao administrador de recursos para ativar a autenticação do Smartsheet para usar este recurso.",
  "lbl_destination_dropdown_select_workspace": "Selecionar uma área de trabalho",
  "lbl_destination_dropdown_search_for_workspace": "Pesquisar uma área de trabalho",
  "msg_advanced_report_ready_title": "O relatório avançado está pronto!",
  "msg_advanced_report_ready": "Você pode acessar e visualizar o status do relatório avançado na página Análise quando quiser",
  "lbl_advanced_report_view_btn": "Ver relatório avançado",
  "msg_advanced_report_creating_title": "Criação do relatório avançado",
  "msg_advanced_report_creating": "Isso pode levar alguns minutos. É possível pré-visualizar o relatório com dados de exemplo agora e, quando ele estiver pronto, os dados reais aparecerão.",
  "lbl_advanced_report_preview_btn": "Pré-visualizar relatório...",
  "msg_advanced_report_creation_footer": "Agora você já pode fechar o documento. O documento fica disponível na seção de relatórios salvos ou na área de trabalho selecionada, então você pode consultá-lo mais tarde.",
  "lbl_advanced_report_creation_footer_link": "Confira dicas sobre relatórios avançados.",
  "lbl_create_advanced_report": "Criar relatório avançado",
  "lbl_edit_settings": "Editar configurações do relatório",
  "err_create_report_failed_generic": "Ocorreu um erro ao criar o relatório. Tente novamente.",
  "err_edit_report_failed_generic": "Ocorreu um erro ao editar o relatório. Tente novamente.",
  "err_create_report_failed_invalid_name": "O nome do relatório não deve ter caracteres especiais nem emojis",
  "msg_session_expired_title": "Sua sessão expirou",
  "msg_session_expired_description": "Sua sessão nesta janela não é mais válida. Clique no botão abaixo para atualizar a página. Você pode precisar fazer login novamente.",
  "lbl_refresh_page": "Atualizar página",
  "msg_refresh_advanced_report": "Atualize os dados pelo menu para aplicar os filtros ao relatório avançado. Seu relatório salvo do RM também será atualizado.",
  "msg_advanced_report_too_many_cells": "Este relatório não foi atualizado porque tem 500.000 ou mais células. Ajuste as configurações no painel esquerdo para reduzir o número de células. <0>Saiba mais</0>",
  "msg_advanced_report_too_many_columns": "Este relatório não foi atualizado porque tem 400 ou mais colunas. Ajuste as configurações no painel esquerdo para reduzir o número de colunas. <0>Saiba mais</0>",
  "msg_advanced_report_too_many_rows": "Este relatório não foi atualizado porque tem 20.000 ou mais linhas. Ajuste as configurações no painel esquerdo para reduzir o número de linhas. <0>Saiba mais</0>",
  "msg_advanced_report_auto_refresh_disabled": "Se a configuração de atualização deste relatório estiver definida como automática, ela será alterada para manual a fim de evitar novas falhas nas atualizações."
}